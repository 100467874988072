import { Title } from '@solidjs/meta';
import { Button, Container, Details, Heading, HorizontalRule, Link, Page, Section, Summary } from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { PictureSection } from '../../components/layouts/picture-section';
import type { JSX } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';

export default function EventPage() {
	return (
		<NoHydration>
			<Title>Cobra Puma Pro-Am at Pebble Beach | Events | Troon</Title>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/web/events/cobra-puma-pro-am/hero.png`}>
				<Heading as="h1">Cobra Puma Pro-Am</Heading>
				<p>February 11-16, 2025 at Pebble Beach</p>
			</Hero>

			<Container>
				<Page>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/web/events/cobra-puma-pro-am.jpg`}>
						<Heading as="h2">Event Details</Heading>
						<ul class="flex list-disc flex-col gap-2 ps-6">
							<li>
								<b>Dates:</b> February 11 – 16, 2025
							</li>
							<li>288 Players</li>
							<li>72 teams</li>
						</ul>
						<ul class="flex list-disc flex-col gap-2 ps-6">
							<li>5 nights of accommodations at The Inn at Spanish Bay (Tuesday, February 11 – Sunday, February 16)</li>
							<li>4 rounds of tournament golf Wednesday — Saturday</li>
							<li>
								First 3 rounds will be played at Pebble Beach Golf Links, Spyglass Hill, and Spanish Bay. Final round
								will be played at either: Pebble Beach Golf Links, Spyglass Hill, or Spanish Bay
							</li>
							<li>Breakfast daily: Wednesday — Sunday </li>
							<li>Welcome reception (Tuesday Night)</li>
							<li>Evening awards reception (Saturday Night)</li>
							<li>Evening hospitality events (Wednesday and Thursday)</li>
							<li>
								<b>For Amateurs:</b> Tee gifts from Cobra Puma valued at $750
							</li>
							<li>
								<b>For Pros:</b> a VIP gift pack valued at $350 from Puma and a purse of $10,000
							</li>
						</ul>

						<Button as={Link} href="https://on.lenos.com/troon/pebble-beach2025-registration/Registration/Team/Team">
							Register now
						</Button>
					</PictureSection>

					<HorizontalRule />

					<Section>
						<p>
							We, in collaboration with Cobra Puma and our amazing host venue in Pebble Beach, are excited to welcome
							216 players, 72 PGA Professionals to the 2025 Cobra Puma Pro Am.
						</p>

						<p>
							This 6 day, 5 night Pro Am features 4 rounds of golf (Pebble Beach, Spanish Bay and Spyglass), numerous
							hospitality and dining events and luxury accommodations at the Inn at Spanish Bay. Concierge services
							available for additional reservations.
						</p>

						<p>The event will take place February 11 – 16, 2025. Registration is open!</p>

						<p>
							To secure your team’s spot at the Cobra Puma Pro Am, a designated team captain must register the team
							(there is a $3,000 deposit). Cancellation/Refund policies are outlined on the registration form.
						</p>

						<p>
							After your team is secured, the individual who filled out the form will be sent an automatic email. It is
							the responsibility of this individual to forward the email to each team member. All 4 individuals (1
							Professional + 3 Amateurs) will need to fill out this form to properly reserve the team. This form will
							allow each individual to inform + pay for their chosen accommodations.
						</p>

						<p>*The PGA Professional’s fee will be complimentary under the following circumstances:</p>

						<ul class="flex list-disc flex-col gap-2 ps-6">
							<li>They provide a completed team of three (3) amateurs</li>
							<li>Share a room with one (1) of the listed amateurs</li>
						</ul>

						<p>
							If the Professional chooses to have their own room accommodations and/or bring a guest, there will be a
							fee associated with those options.
						</p>

						<For each={pricing}>
							{(pricing) => (
								<table>
									<thead>
										<tr>
											<th
												class="rounded-t border border-neutral bg-brand-700 px-4 py-2 text-start font-semibold text-white"
												colSpan={2}
											>
												{pricing.title}
											</th>
										</tr>
									</thead>
									<tbody>
										<For each={pricing.items}>
											{(item) => (
												<tr>
													<th scope="row" class="border border-neutral px-4 py-2 text-start font-normal">
														{item.item}
													</th>
													<td class="border border-neutral px-4 py-2">{item.pricing}</td>
												</tr>
											)}
										</For>
									</tbody>
								</table>
							)}
						</For>

						<p class="text-sm italic">
							A 3% processing fee is already built into the “room option” pricing when you fill out your individual
							form.
						</p>

						<Button
							as={Link}
							href="https://on.lenos.com/troon/pebble-beach2025-registration/Registration/Team/Team"
							class="mx-auto size-fit"
						>
							Register now
						</Button>
					</Section>

					<HorizontalRule />

					<Section>
						<Heading as="h2">Frequently asked questions</Heading>
						<div class="rounded border border-neutral">
							<NoHydration>
								<ul class="divide-y divide-neutral-500">
									<For each={Object.entries(faqs)}>
										{([question, answer]) => (
											<li>
												<Details>
													<Summary class="px-4 py-6 group-open:mb-0 group-open:pb-4">{question}</Summary>
													<div class="px-4 pb-4">{answer()}</div>
												</Details>
											</li>
										)}
									</For>
								</ul>
							</NoHydration>
						</div>
						<p class="text-center">
							Pebble Beach Photo: <b>Photo courtesy of Pebble Beach Company</b>
						</p>
					</Section>
				</Page>
			</Container>
		</NoHydration>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const pricing: Array<{ title: string; items: Array<{ item: string; pricing: string }> }> = [
	{
		title: 'Amateur “All In” Pricing',
		items: [
			{ item: 'Double Occupancy', pricing: '$9,941.43/per Amateur' },
			{ item: 'Forest + Garden View – Single Occupancy', pricing: '$10,443.26/per Amateur' },
			{ item: 'Scenic View – Single Occupancy', pricing: '$12,534.97/per Amateur' },
			{ item: 'Ocean View – Single Occupancy', pricing: '$12,688.76/per Amateur' },
		],
	},
	{
		title: 'Pros “All In” Pricing',
		items: [
			{ item: 'Golf Professional sharing a room with one (1) Amateur “Room Sharing Option”', pricing: '$0/per Pro' },
			{ item: 'Single Occupancy Pro', pricing: '$4,144.39/per Pro' },
			{ item: '2 Pros, 1 Room Sharing', pricing: '$2,072.20/per Pro' },
		],
	},
	{ title: 'Add On’s', items: [{ item: 'Add a Non-playing guest', pricing: '$1,152.84/per Guest' }] },
];

const faqs: Record<string, () => JSX.Element> = {
	'Golf At Pebble Beach': () => (
		<ul class="flex list-disc flex-col gap-2 ps-6">
			<li>Four person teams. Three amateurs and one PGA professional per team.</li>
			<li>Four rounds of tournament golf – Wednesday through Saturday.</li>
			<li>Each Team will play the first 3 rounds at Pebble Beach, Spyglass Hill, & Spanish Bay.</li>
			<li>
				The field will be split based on event standing after round 3 to determine the final round course each team will
				play.
			</li>
		</ul>
	),
	'Tournament Format': () => (
		<>
			<p>SCORING FORMAT</p>
			<p>Team Event</p>
			<ul class="flex list-disc flex-col gap-2 ps-6">
				<li>Gross Division – 72 Holes, 2 Best Balls</li>
				<li>Net Division – 72 Holes, 2 Best Balls – Modified Stableford Scoring with Quota adjustments daily.</li>
			</ul>
			<p>Individual Event</p>
			<ul class="flex list-disc flex-col gap-2 ps-6">
				<li>PGA Professionals – 54 Holes, first 3 days, gross stroke play scoring.</li>
				<li>Low Amateur – 54 Holes, first 3 days, gross stroke play scoring.</li>
			</ul>
			<p>Tournament Cut:</p>
			<p>
				The field will be split after round 3 (Friday) . The top ⅓ of the field from the GROSS and NET divisions will
				play their 4th round at Pebble Beach on Saturday. The next 1/3 of the field will play at Spyglass Hill and the
				final 1/3 will play at Spanish Bay on Saturday.
			</p>
			<p>Handicaps:</p>
			<p>
				Each player’s course handicap will be used to establish their first day quota and strokes in the net skins game,
				if they opt in. Each player’s (quota) will be adjusted each day up or down based on their Quota Score the day
				prior. We will be using each amateurs February 12, 2024 index. Amateurs receive 80% of their course handicap.
			</p>
			<p>Adjusted Handicap (Quota) details:</p>
			<p>
				Each Amateur’s GHIN Index will be placed into the standard course slope & handicap rating system to determine
				initial course handicap. Amateurs and Professionals will receive an adjusted quota daily based on the grid
				below.
			</p>
			<p>
				The maximum submitted handicap index for all players is 22. The tournament committee has the option to adjust a
				player’s handicap at any time during the tournament. Quota is adjusted daily.
			</p>
			<p>
				TIES
				<br />
				USGA 9-6-3-1
			</p>
		</>
	),
	'Prizes & Optional Competitions': () => (
		<>
			<p>
				<strong>OVERALL PRIZES (TEAM + PROFESSIONALS)</strong>
			</p>
			<p>Top six (6) overall teams [Net and Gross] and Top six (7) Professionals will win</p>
			<p>
				A team can only place in one division. If a team places in both divisions, they will be awarded the higher
				place. If a team finishes in the same place in both divisions, gross supersedes net.
			</p>
			<p>Tournament awards presented at Saturday Night’s Awards Banquet.</p>
			<p>
				<strong>OPTIONAL COMPETITIONS</strong>
			</p>
			<p>On-Course: There will be optional daily hole-in-one competitions available</p>
			<p>
				Team Buy-in: Each team will have the option to buy-in on the daily skins game & day money. Winners paid daily.
			</p>
		</>
	),
	Policies: () => (
		<>
			<p>
				<b>POLICY FOR PLAY</b>
			</p>
			<p>Our Pro Am policy for all players is designed for maximum enjoyment and equality in competition.</p>
			<p>
				Troon Events Pro-Am Senior play policy is 55 years & older. (Amateurs & Professionals). Date of birth is
				to-the-day of tournament start.
			</p>
			<p>
				<b>TEES & YARDAGES</b>
			</p>
			<p>COMING SOON</p>
			<p>
				<b>*Tees and yardages are subject to change prior to the start of the event</b>
			</p>
		</>
	),
	'Refund And Cancellation Policies': () => (
		<ul class="flex list-disc flex-col gap-2 ps-6">
			<li>
				Each Team’s $3,000 deposit is fully refundable until 7/15/2024. Deposits made 7/16/2024 and after are
				non-refundable.
			</li>
			<li>
				Full payment for each individual is due 7/15/2024 or fourteen (14) days after Team registration date. If full
				payment, for each individual, is not reached by 7/16/2024 or at 12:00 AM fourteen (14) days after Team
				registration date, then your team’s spot may be forfeited and given to another team.
			</li>
			<li>Individual registration payments are fully refundable until 10/1/2024.</li>
			<li>
				Payments made 10/2/2024 and beyond will be non-refundable unless you find somebody to take your place on the
				team.
			</li>
		</ul>
	),
};
